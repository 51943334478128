import React, {useState, useEffect, useRef} from 'react';
import Dialog from '@mui/material/Dialog';
import ReactPlayer from 'react-player';
import {IClipResponse, TVideoSourceType} from '@my-game-plan/types';
import {getFullMatchVideo} from '@/controllers/video/video.controller';
import FullMatchVideoControlBar from '../controls/FullMatchVideoControlBar';

import ANALYTICS_EVENT from '@/config/analytics/event-names.config';
import {useAnalytics} from '@/context/analytics.context';

interface IBareVideoPlayerProps {
  isOpened: boolean;
  onClose: () => void;
  matchId: string;
  sourceType: TVideoSourceType;
  forceHLS: boolean;
  startClipResponse: IClipResponse | null;
}

const VIDEO_WIDTH = '100%';
const VIDEO_HEIGHT = '100%';
const VIDEO_STRETCH = {width: '100%', height: '100%', objectFit: 'fit'};
export const CHUNK_DURATION = 300; // Duration of each chunk in seconds

function ChunkedBareVideoPlayerView(props: IBareVideoPlayerProps): JSX.Element {
  const [currentChunkUrl, setCurrentChunkUrl] = useState<string | null>(
    props.startClipResponse?.clip_url || null,
  );
  const [currentChunkStart, setCurrentChunkStart] = useState<number>(0);
  const [nextChunkUrl, setNextChunkUrl] = useState<string | null>(null);
  const [isPrefetching, setIsPrefetching] = useState<boolean>(false);
  const [_isPlaying, _setIsPlaying] = useState<boolean>(false);
  const [totalDuration, setTotalDuration] = useState<number>(
    props.startClipResponse?.total_duration || 0,
  ); // Full video duration
  const [playedSeconds, setPlayedSeconds] = useState<number>(0); // Total played seconds
  const [volume, setVolume] = useState<number>(0); // Default volume level is 0%

  const videoRef = useRef<ReactPlayer>(null);
  const _analyticsContext = useAnalytics();

  const VIDEO_CONFIG = {
    file: {
      attributes: {
        style: VIDEO_STRETCH,
      },
      forceHLS: props.forceHLS,
      hlsVersion: '1.4.12',
    },
  };

  useEffect(() => {
    if (!props.isOpened) {
      resetPlayerState();
    }
  }, [props.isOpened]);

  useEffect(() => {
    if (!currentChunkUrl && props.startClipResponse?.clip_url)
      setCurrentChunkUrl(props.startClipResponse.clip_url);
    if (!totalDuration && props.startClipResponse?.total_duration)
      setTotalDuration(props.startClipResponse.total_duration);
  }, [props.startClipResponse]);

  const resetPlayerState = () => {
    setCurrentChunkUrl(null);
    setNextChunkUrl(null);
    setPlayedSeconds(0);
    _setIsPlaying(false);
  };

  const fetchChunk = async (
    startTime: number,
    reset = false,
  ): Promise<void> => {
    try {
      const clipResponse = await getFullMatchVideo(props.matchId, {
        source: props.sourceType,
        offsets: [startTime, startTime + CHUNK_DURATION],
      });
      _analyticsContext.trackEvent(
        ANALYTICS_EVENT.WATCHED_FULL_MATCH_NEXT_PART,
        {
          minute: Math.floor(startTime / 60),
          second: startTime % 60,
          match_id: props.matchId,
          wyscout_id: '', // wyscout id is not available in this component
          video_source: props.sourceType,
        },
      );

      if (currentChunkUrl === null || reset) {
        setCurrentChunkUrl(clipResponse.clip_url);
        setCurrentChunkStart(startTime);
      } else {
        setNextChunkUrl(clipResponse.clip_url);
      }
      setIsPrefetching(false);
    } catch (error) {
      console.error('Failed to fetch video chunk:', error);
    }
  };

  const handleProgress = (state: {playedSeconds: number}) => {
    const absolutePlayedSeconds = currentChunkStart + state.playedSeconds;
    setPlayedSeconds(absolutePlayedSeconds);

    const timeRemaining =
      CHUNK_DURATION - (state.playedSeconds % CHUNK_DURATION);

    if (timeRemaining <= 5 && !isPrefetching && nextChunkUrl === null) {
      const nextChunkStart =
        Math.floor(
          (currentChunkStart + state.playedSeconds) / CHUNK_DURATION + 1,
        ) * CHUNK_DURATION;
      setIsPrefetching(true);
      fetchChunk(nextChunkStart);
    }
  };

  const handleChunkEnd = () => {
    if (nextChunkUrl) {
      setCurrentChunkUrl(nextChunkUrl);
      setCurrentChunkStart(currentChunkStart + CHUNK_DURATION);
      setNextChunkUrl(null);
    } else {
      _setIsPlaying(false);
    }
  };

  const handleSeek = (progress: number) => {
    const seekTime = progress * totalDuration;
    const newChunkIndex = Math.floor(seekTime / CHUNK_DURATION);
    const relativeTime = seekTime % CHUNK_DURATION;

    if (newChunkIndex * CHUNK_DURATION != currentChunkStart) {
      setCurrentChunkUrl(null);
      setNextChunkUrl(null);
      fetchChunk(newChunkIndex * CHUNK_DURATION, true).then(() => {
        if (videoRef?.current) {
          videoRef.current.seekTo(relativeTime, 'seconds');
        }
      });
    } else {
      if (videoRef?.current) {
        videoRef.current.seekTo(relativeTime, 'seconds');
      }
    }
    setPlayedSeconds(seekTime);
  };
  return (
    <Dialog
      open={props.isOpened}
      onClose={props.onClose}
      maxWidth={false}
      fullWidth>
      <div style={{position: 'relative'}}>
        <ReactPlayer
          ref={videoRef}
          url={currentChunkUrl || ''}
          width={VIDEO_WIDTH}
          height={VIDEO_HEIGHT}
          config={VIDEO_CONFIG}
          controls={false}
          playing={_isPlaying}
          onReady={() => _setIsPlaying(true)}
          onProgress={handleProgress}
          onEnded={handleChunkEnd}
          progressInterval={100}
          volume={volume}
          onDuration={(duration) => {
            if (!totalDuration) {
              // Store the duration if available
              setTotalDuration(duration);
            }
          }}
        />
        <FullMatchVideoControlBar
          playing={_isPlaying}
          setTime={handleSeek}
          flipVideoState={() => _setIsPlaying(!_isPlaying)}
          handleSeek={false}
          secondsPlayed={playedSeconds}
          duration={totalDuration}
          videoOffsets={undefined}
          volume={volume}
          setVolume={setVolume}
        />
      </div>
    </Dialog>
  );
}

export default ChunkedBareVideoPlayerView;
