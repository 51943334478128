import MatchVideosFilterBar from '@/components/match-videos/match-videos-filter-bar.view';

import {
  MatchVideosProvider,
  useMatchVideos,
} from '@/context/match-videos-context';

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import MatchVideosTable from '@/components/match-videos/match-videos-table/match-videos-table.view';
import ScreenContent from '@/components/screen/screen-content.view';
import {useTeams} from '@/context/team.context';

function MatchVideosComponent(): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _matchVideosContext = useMatchVideos();

  /*
   * Handlers
   */

  /*
   * Render
   */

  return (
    <ScreenContent loadingState={_matchVideosContext.loadingState}>
      <Stack gap={4}>
        <MatchVideosFilterBar />
        {_matchVideosContext.matches.map((matchDay) => {
          return (
            <Card key={matchDay.matchDay}>
              <CardHeader
                title={t('matches.matchDayCount', {
                  matchDay: matchDay.matchDay,
                })}
              />

              <CardContent>
                <MatchVideosTable matches={matchDay.matches} />
              </CardContent>
            </Card>
          );
        })}
      </Stack>
    </ScreenContent>
  );
}

function MatchVideosView(): JSX.Element {
  return (
    <MatchVideosProvider>
      <MatchVideosComponent />
    </MatchVideosProvider>
  );
}

export default MatchVideosView;
