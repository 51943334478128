import {IClipResponse, TVideoSourceType} from '@my-game-plan/types';
import {IEditedClip, IGetClipQueryParams} from '@/types/clips.types';
import {handleVideoDownload} from '@/helpers/video.helper';
import {useAPI} from '@/hooks/api.hooks';
import {Resource} from '@/types/api.types';
import {CLIPS_API_CONFIG} from '@/config/api.config';
import axios from 'axios';
import {REQUEST_ERRORS} from '@/config/errors.config';

const api = useAPI();

export const getVideo = async (
  eventID: string,
  teamID: string,
  params: IGetClipQueryParams,
): Promise<IClipResponse> => {
  try {
    const {data} = await api.get<IClipResponse>({
      resource: Resource.clips,
      url: CLIPS_API_CONFIG.getVideo(eventID, teamID),
      params: params,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// TODO should be converted as well
export const downloadVideos = async (
  eventIds: string[],
  teamId: string,
  zipName: string,
  sourceType?: TVideoSourceType,
): Promise<void> => {
  const URL = `${process.env.REACT_APP_MY_GAME_PLAN_SERVER_URL}${Resource.clips}/${CLIPS_API_CONFIG.downloadVideos}`;
  try {
    return new Promise<void>((resolve, reject) => {
      axios
        .post<BlobPart>(
          URL,
          {event_ids: eventIds, team_id: teamId, video_source: sourceType},
          {responseType: 'blob'},
        )
        .then((videos) => {
          handleVideoDownload(videos, zipName, resolve);
        })
        .catch((error) => {
          reject(error);
        });
    });
  } catch (error) {
    throw new Error('Something went wrong while preparing the download.');
  }
};

/*
 * Save clip
 */
export const saveEditedClip = async (params: IEditedClip): Promise<boolean> => {
  const {team_id, event_id, ..._requestBody} = params;
  try {
    const {data} = await api.put<boolean>({
      resource: Resource.clips,
      url: CLIPS_API_CONFIG.saveEditedClip(team_id, event_id),
      data: _requestBody,
      options: {
        headers: {'content-type': 'application/json'},
      },
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    return false;
  }
};

/*
 * Full match video
 */
export async function getFullMatchVideo(
  matchId: string,
  params: IGetClipQueryParams,
): Promise<IClipResponse> {
  try {
    const _URL = `clips/match/${matchId}`;
    const {data} = await api.get<IClipResponse>({
      url: _URL,
      params: params,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}
