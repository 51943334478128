import React from 'react';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import {FIMatch} from '@my-game-plan/types';
import moment from 'moment';
import {MatchScore} from '@/components/common/match-score/MatchScore';
import MatchVideoStatusCell from './match-video-status-cell.view';
import {useMatchVideos} from '@/context/match-videos-context';

interface IMatchVideosTableRowProps {
  match: FIMatch;
}

function MatchVideosTableRow(props: IMatchVideosTableRowProps): JSX.Element {
  /*
   * Hooks n State
   */
  const _matchVideosContext = useMatchVideos();

  /*
   * Side effects
   */

  /*
   * Handlers
   */

  /*
   * Render
   */
  return (
    <TableRow>
      <TableCell sx={{color: 'text.secondary'}}>
        {moment(props.match.date).format('LL')}
      </TableCell>
      <TableCell>
        <MatchScore match={props.match} displayTeamNames />
      </TableCell>

      <MatchVideoStatusCell
        sourceType="broadcast"
        match={props.match}
        videoConfig={props.match.video?.broadcast}
      />

      <MatchVideoStatusCell
        sourceType="tactical"
        match={props.match}
        videoConfig={props.match.video?.tactical}
        canManage
        hasAutomaticOffsets={
          _matchVideosContext.selectedCompetition?.tactical_video_offsetting ===
          'ocr'
        }
      />
    </TableRow>
  );
}

export default MatchVideosTableRow;
