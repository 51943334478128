import React, {memo, useEffect, useRef, useState} from 'react';

import Fullscreen from '@mui/icons-material/Fullscreen';
import FullscreenExit from '@mui/icons-material/FullscreenExit';
import Pause from '@mui/icons-material/Pause';
import PlayArrow from '@mui/icons-material/PlayArrow';

import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

import styles from './VideoControlBar.module.scss';

import screenfull from 'screenfull';
import {useTranslation} from 'react-i18next';

import {
  alpha,
  darken,
  ListItem,
  ListItemText,
  Slider,
  useTheme,
} from '@mui/material';
import {timestampToSecondsString} from '@/helpers/video.helper';
interface FullMatchVideoControlBarProps {
  playing: boolean;
  handleSeek: boolean;
  volume: number;
  secondsPlayed: number;
  setTime: (time: number) => void;
  duration: number;
  videoOffsets: any; // Modify according to the type of videoOffsets
  flipVideoState: () => void;
  setVolume: (vol: number) => void;
}

function FullMatchVideoControlBar({
  playing,
  secondsPlayed,
  setTime,
  duration,
  flipVideoState,
  volume,
  setVolume,
}: FullMatchVideoControlBarProps) {
  /*
   * Hooks n State
   */
  const _theme = useTheme();

  const [timeString, setTimeString] = useState<string>('');
  const [_xMouseOnProgressBar, _setxMouseOnProgressBar] = useState(0);
  const [showTimeOnProgressBar, _setShowTimeOnProgressBar] = useState(false);

  const [_isFullScreen, _setIsFullscreen] = useState(false);

  const {t} = useTranslation();
  const _progressBarWidth = useRef<HTMLDivElement>(null);

  const _bgColor = useRef<string>(
    alpha(darken(_theme.palette.background.default, 0.3), 0.9),
  );

  screenfull.onchange(() => {
    _setIsFullscreen(screenfull.isFullscreen);
  });

  useEffect(() => {
    const _startString = timestampToSecondsString(secondsPlayed, 1);

    const _endString = timestampToSecondsString(duration, 1);

    const _timeString = `${_startString} - ${_endString}`;
    setTimeString(_timeString);
  }, [duration, secondsPlayed]);

  /*
   * Handlers
   */
  function _calcXMouseOnProgressBar(e: React.MouseEvent<HTMLDivElement>) {
    const target = e.target as HTMLDivElement;
    const rect = target?.getBoundingClientRect();
    const x_pos = (e.clientX - rect.left) / rect.width;
    _setxMouseOnProgressBar(x_pos);
  }

  function _onMouseEnterProgressBar(e: React.MouseEvent<HTMLDivElement>) {
    _setShowTimeOnProgressBar(true);
    _calcXMouseOnProgressBar(e);
  }

  function _toggleFullscreen() {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  }
  function _calcXProgressBarPopup() {
    const threshold = 10;
    const marginRight = 15;
    let res =
      _xMouseOnProgressBar *
      (_progressBarWidth.current ? _progressBarWidth.current.clientWidth : 0);
    res -= marginRight;

    return res <= threshold ? threshold : res;
  }

  /*
   * Render
   */
  const _playIcon = playing ? (
    <Pause className={styles.control_bar_icon} />
  ) : (
    <PlayArrow className={styles.control_bar_icon} />
  );

  const _VolumeIcon = !volume ? (
    <VolumeOffIcon className={styles.control_bar_icon} />
  ) : (
    <VolumeUpIcon className={styles.control_bar_icon} />
  );

  const _screenFullIcon = _isFullScreen ? (
    <FullscreenExit className={styles.control_bar_icon}></FullscreenExit>
  ) : (
    <Fullscreen className={styles.control_bar_icon} />
  );

  return (
    <Box
      style={{justifyContent: 'center'}}
      sx={{
        bgcolor: _bgColor.current,
      }}>
      <div
        ref={_progressBarWidth}
        className={styles.progress_bar_div}
        onMouseEnter={(x) => _onMouseEnterProgressBar(x)}
        onMouseOut={() => _setShowTimeOnProgressBar(false)}
        onMouseMove={(x) => _calcXMouseOnProgressBar(x)}
        onClick={() => {
          setTime(_xMouseOnProgressBar);
        }}>
        <div className={styles.no_pointer_events}>
          <LinearProgress
            color={'primary'}
            variant="determinate"
            value={(secondsPlayed / duration) * 100}
            sx={{
              '& .MuiLinearProgress-bar': {transition: 'transform 0s linear'},
            }}
          />
        </div>
        <div className={styles.no_pointer_events}>
          <div
            className={styles.progress_time_hover}
            style={{left: _calcXProgressBarPopup()}}>
            {showTimeOnProgressBar
              ? timestampToSecondsString(_xMouseOnProgressBar * duration, 1)
              : ''}
          </div>
        </div>
      </div>

      <Box className={styles.action_bar}>
        <Stack
          direction="row"
          justifyContent={'space-between'}
          sx={{height: '100%'}}>
          <Stack
            pl={2}
            spacing={1}
            direction="row"
            alignItems={'center'}
            divider={
              <Divider
                className={styles.custom_divider}
                orientation="vertical"
                flexItem
              />
            }>
            <Box>
              {/* Play / Pause */}
              <Tooltip
                placement="top"
                title={
                  playing
                    ? t('video-player.tooltip.pause')
                    : t('video-player.tooltip.play')
                }>
                <IconButton
                  sx={{color: 'text.primary'}}
                  onClick={flipVideoState}
                  className={styles.IconButton}>
                  {_playIcon}
                </IconButton>
              </Tooltip>
            </Box>
            {/* Time indicator */}
            <Box px={1}>
              <Typography
                fontStyle={'italic'}
                variant="body2"
                className={styles.time}
                color="text.secondary">
                {timeString}
              </Typography>
            </Box>
          </Stack>
          <Stack
            pr={2}
            direction="row"
            spacing={1}
            alignItems={'center'}
            divider={
              <Divider
                className={styles.custom_divider}
                orientation="vertical"
                flexItem
              />
            }>
            {/* Settings */}
            {/* Contains volume, autoplay and angle */}
            <ListItem divider>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'body2',
                  color: 'text.secondary',
                }}>
                {t('video-player.tooltip.volume')}
              </ListItemText>
              <Stack direction="row" alignItems="center" gap={0.5}>
                <Tooltip
                  placement="top"
                  title={
                    !volume
                      ? t('video-player.tooltip.unmute')
                      : t('video-player.tooltip.mute')
                  }>
                  <IconButton size="small" onClick={() => setVolume(0)}>
                    {_VolumeIcon}
                  </IconButton>
                </Tooltip>
                <Slider
                  orientation="horizontal"
                  min={0}
                  max={1}
                  step={0.1}
                  aria-label="Volume"
                  value={volume}
                  onChange={(e, newVal) => setVolume(newVal as number)}
                  color="secondary"
                  sx={{
                    color: 'text.primary',
                    width: 80,
                    '& .MuiSlider-thumb': {
                      height: 12,
                      width: 12,
                    },
                  }}
                />
              </Stack>
            </ListItem>

            {/* Full screen toggle */}
            <Tooltip
              placement="top"
              title={t('video-player.tooltip.fullscreen')}>
              <IconButton
                sx={{color: 'text.secondary'}}
                onClick={_toggleFullscreen}
                className={styles.IconButton}>
                {_screenFullIcon}
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}

export default memo(FullMatchVideoControlBar);
